import { ChangeEvent, useContext, useEffect, useState } from "react";
import { useLocation, useNavigate} from "react-router-dom";
import MyAxiosClass, { Carticle } from "../api/phpApi";

import { TheContext } from "../context/AdminContext";
import Article from "../components/Article";

interface Iarticle{
    article_id: number,
    article_title: string,
    title: string,
    at: string,
    category: number,
    dt_start: Date,
    dt_end: Date,
    details: string,
    icon: string,
    company: number,
    image: string,
    folder: string
}


export default function Actualities({...props}) {
    const [articles, setArticles] = useState<Carticle[]>();
    const [openCreateForm, setOpenCreateForm] = useState<boolean>(false);
    const [defaultImagePath, setDefaultImagePath] = useState<string>("")
    const [lastinsertId, setLastInsertId] = useState<number>(-1);
    const location = useLocation();
    const navigate = useNavigate();
    const theContext = useContext(TheContext);
    const newArticle = new Carticle(-1, "", "", "", "", 1 , new Date(), new Date(), "", "GiMeditation", 1 , "", "equilys", true,"");
    console.log (location);
   
    
    const createNew = () =>{
       
        console.log("créer une nouvelle actu");
        // init article vierge
        // create new
         
           // crée l'article avec les infos par défaut
           MyAxiosClass.createNewArticle(newArticle).then((resp: any)=>{
            if(resp.error){
                console.log("erreur : ", resp.error);
                setLastInsertId(-1);
            }else{
                console.log("lastinsert : ", resp.lastinsert);
                setLastInsertId(parseInt(resp.lastinsert, 10));
                theContext.setReloadData(true);
            }            
            
        }).catch((error: any)=>{
        setLastInsertId(-1);
        console.log("create error : ", error)});
        
    }
    
    /**mount */
    useEffect(()=>{
    const recupFromLogin = location.state;
    console.log ("recup : ", recupFromLogin);
    getList();
    
    async function getList(){
        const myRes = await MyAxiosClass.getArticles();
        let tempArticles: Carticle[];
        if (myRes !== undefined && myRes.articles){
            tempArticles = Object.values(myRes.articles.data).map((anArticle: any)=>{
                let respArticle =  new Carticle(parseInt(anArticle.article_id, 10), anArticle.article_title, anArticle.article_subtitle, anArticle.title, anArticle.at, parseInt(anArticle.category, 10), new Date(anArticle.dt_start), (anArticle.dt_end ? new Date(anArticle.dt_end):null), anArticle.details, anArticle.icon, parseInt(anArticle.company, 10), anArticle.image, anArticle.folder, (anArticle.to_publish.toString() === "0"? false : true), anArticle.flyer);
                respArticle.option1 = anArticle.option1 ? (anArticle.option1.toString() === "0"? false : true) : false;
                respArticle.hour = anArticle.hour;
                return respArticle;

            })
            if(tempArticles){                    
                setArticles(tempArticles); 
                console.log("articles load : ", tempArticles);
            }
           
        }else{
            console.log("chargement en cours");
        }
    }
}, []);

    /**update */
    useEffect(()=>{
        console.log ("update");
        if (theContext.reloadData){
            console.log ("dans condition");
            getList();
            theContext.setReloadData(false);    
        }
        /**
         * ueGetList
         */
        async function getList(){
            const myRes = await MyAxiosClass.getArticles();
            let tempArticles: Carticle[];
            if (myRes !== undefined && myRes.articles){
                tempArticles = Object.values(myRes.articles.data).map((anArticle: any)=>{
                    let respArticle =  new Carticle(parseInt(anArticle.article_id, 10), anArticle.article_title, anArticle.article_sutitle, anArticle.title, anArticle.at, parseInt(anArticle.category, 10), new Date(anArticle.dt_start), (anArticle.dt_end ? new Date(anArticle.dt_end):null), anArticle.details, anArticle.icon, parseInt(anArticle.company, 10), anArticle.image, anArticle.folder, (anArticle.to_publish.toString() === "0"? false : true), anArticle.flyer);
                    respArticle.option1 = anArticle.option1 ? (anArticle.option1.toString() === "0"? false : true) : false;
                    respArticle.hour = anArticle.hour;
                    return respArticle;
                    
                })
                if(tempArticles){                    
                    setArticles(tempArticles); 
                }
               
            }else{
                console.log("chargement en cours");
            }
        }
    })
   
    function createdArticle(theArticles: Carticle[] | undefined):Carticle[] | undefined{
        const createdArticle = theArticles?.filter((anArticle: Carticle)=>(anArticle.article_id === lastinsertId))
        if(createdArticle)
        return createdArticle;
    }
    function furtherArticles(lastinsertId: number):Carticle[] | undefined{
        const furtherArticles = articles && articles.filter((anArticle: Carticle)=>(new Date(anArticle.dt_start).getTime() >= new Date().getTime()&& anArticle.article_id !== lastinsertId))
        if(furtherArticles){
            furtherArticles.sort((a, b) => new Date(a.dt_start).getTime() - new Date(b.dt_start).getTime());
            return furtherArticles;
        }
        
    }
    function previousArticles(lastinsertId: number):Carticle[] | undefined{
        const previousArticles = articles && articles.filter((anArticle: Carticle)=>(new Date(anArticle.dt_start).getTime() < new Date().getTime() && anArticle.article_id !== lastinsertId))
        if(previousArticles){
            previousArticles.sort((a, b) => new Date(a.dt_start).getTime() - new Date(b.dt_start).getTime());
            return previousArticles;
        }
        
    }
   return(
    <div className="actualities-container">
    <h1 className="page-adminactu-title">
        Actualités
    </h1>
    <button className="edit-btn" type="button" onClick={createNew}>Créer</button>
    {articles && lastinsertId !== -1 && createdArticle(articles)?.map((theNewArticle: Carticle)=>
        <Article  {...theNewArticle}/>
   )
   }
    
    <h2>À venir</h2>
    <div className="article-list">
    <div className="articles-container">
        {articles && furtherArticles(lastinsertId) ? furtherArticles(lastinsertId)?.map((anArticle: Carticle)=>(
         <Article {...anArticle} />
        )): "Pas de stage prévu prochainement"}
    </div>
    <h2>{articles && previousArticles(lastinsertId) && previousArticles(lastinsertId) && "Historique"}</h2>
    <div className="articles-container">
        {articles && previousArticles(lastinsertId) && previousArticles(lastinsertId)?.map((anArticle: Carticle)=>(
         <Article {...anArticle}/>
        ))}
    </div>
    </div>
    
    </div>
   )
}