
import React, { useEffect, useRef, useState } from 'react'
import '../style/DatePicker.css'
import {BsCalendar4} from "react-icons/bs";

class CinRange {
    id: number;
    date: Date;
    times?: number;

constructor(pId: number, pDate: Date){
    this.id = pId;
    this.date = pDate;
}
}

interface Imonth{
    id: number,
    label: string,
    numberOfDays: number
}
interface Iday{
    id: number,
    label: string
}
class CFormatYear{
   
    year: number;
    months: CformatBlock[];
    constructor(pYear: number, pmonths: CformatBlock[]){
       
        this.year = pYear;
        this.months = pmonths;
    }
}
class CformatBlock{
    id: number;
    block: CformatDate[] | [];
    constructor(pId: number, pBlock: CformatDate[]){
        this.id = pId;
        this.block = pBlock;
    }
}
class CformatDate{
    isDisabled: boolean;
    date: Date;
    constructor( pDate: Date, pIsDisabled: boolean){     
        this.isDisabled = pIsDisabled;
        this.date = pDate;
    }
}


class CarrangeBlock{
    id: number;
    block: Date[] | [];
    constructor(pId: number, pBlock: Date[]){
        this.id = pId;
        this.block = pBlock;
    }
}



function DatePicker({...props}) {    
    /**
    * 
    * @param param0 BASICS
    * @returns 
    */
    let options: Intl.DateTimeFormatOptions = {
        year: 'numeric',
        month: 'short',
        day: 'numeric',
        weekday: 'short'
    };
    let calendarOptions: Intl.DateTimeFormatOptions = {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
        weekday: 'short'
    };
    
    const localeFormat = props.locale || "fr-fr";
    options = { weekday: 'long', year: 'numeric', month: 'short', day: 'numeric' };
    
    const currentDate = new Date();
    const firstDayoFtheYear = new Date(new Date().getFullYear(), 0, 1).toLocaleDateString(localeFormat, options);
    
    currentDate.getFullYear() //– récupère l'année en 4 chiffres(aaaa)
    currentDate.getMonth() //– récupère le mois sous forme numérique (0-11)
    currentDate.getDate() //– récupère la date sous forme numérique (1-31)
    currentDate.getHours() //– récupère l'heure (0-23)

   
    /*Constantes*/
    const currentYear = currentDate.getFullYear();   
    const currentMonth = currentDate.getMonth(); 
    const months:Imonth[] = [
        {
            id: 0,
            label: "janvier",
            numberOfDays : 31
        },
        {
            id: 1,
            label: "février",
            numberOfDays : 28
        },
        {
            id: 2,
            label: "mars",
            numberOfDays : 31
        },
        {
            id: 3,
            label: "avril",
            numberOfDays : 30
        },
        {
            id: 4,
            label: "mai",
            numberOfDays : 31
        },
        {
            id: 5,
            label: "juin",
            numberOfDays : 30
        },
        {
            id: 6,
            label: "juillet",
            numberOfDays : 31
        },
        {
            id: 7,
            label: "août",
            numberOfDays : 31
        },
        {
            id: 8,
            label: "septembre",
            numberOfDays : 30
        },
        {
            id: 9,
            label: "octobre",
            numberOfDays : 31
        },
        {
            id: 10,
            label: "novembre",
            numberOfDays : 30
        },
        {
            id: 11,
            label: "décembre",
            numberOfDays : 31
        } 
        
    ]
    const daysOfWeek = [
       
        {id: 1, label: "lundi"},
        {id: 2, label: "mardi"},
        {id: 3, label: "mercredi"},
        {id: 4, label: "jeudi"},
        {id: 5, label: "vendredi"},
        {id: 6, label: "samedi"},
        {id: 0, label: "dimanche"}
    ]
    const nbYears = 2; // years plus and less -> then 4 + current
    const nbCalendarBlocks = 42;
    /*State*/
    const [years, setYears] = useState<CFormatYear[]>([]);
    const [truncatedDays, setTruncatedDays] = useState<Iday[]>();
    const [finalBlocks, setFinalBlocks] = useState<CformatBlock[]>([]);
    const [activMonth, setActivMonth] = useState<{year: number, month: number}>({year:currentYear, month:currentMonth});
    const [startDate, setStartDate] = useState<CformatDate | null>(props.startDate ? new CformatDate(new Date(props.startDate), false) : new CformatDate(new Date(), false));
    const [endDate, setEndDate] = useState<CformatDate | null>(props.endDate ? new CformatDate(new Date(props.endDate), false) : null);
    const [clickedTimes, setClickedTimes] = useState<number>(0);
    const [clickedDateIndex, setClickedDateIndex] = useState<number>(-1);
    const [isInRange, setIsInRange] = useState<CinRange[]>([]);
    const [overedStartDateIndex, setOveredStartDateIndex] = useState<number>(-1);
    const [openCalendar, setOpenCalendar]=useState({startdate : false, enddate : false});
    const [letsUpdate, setLetsUpdate] = useState(false);
    /**Refs */
    const timerRef = useRef<any>(null);

    function getLastYear(pYears:CFormatYear[]) {      
        if(pYears)
        return pYears[pYears.length-1];
    }

    function getFirstYear(pYears:CFormatYear[]) {
        if(pYears)
        return pYears[0];
    }
   
    /*Mount*/
    useEffect(()=>{
        const truncDays = daysOfWeek && daysOfWeek.map((aDay: Iday)=>{
            return {...aDay, label: truncateFromStart(aDay.label, 3)};
        })
        setTruncatedDays(truncDays);        
        const tempYears = ueInitYears(currentYear, nbYears);
        if (tempYears)
        setYears(tempYears);
        function ueInitYears (activYear: number, nbYears: number){
            let tempYears: CFormatYear[] = [];        
            for (let i=activYear-nbYears; i<= activYear + nbYears; i++){
                const loadedYear = loadFullYear(i);
                
                if (loadedYear){   
                    const monthsBlocks = formatMonthsBlocks(loadedYear);       
                    if (monthsBlocks){
                        const tempFinalBlocks = formatFinalBlocks(monthsBlocks, nbCalendarBlocks, i);
                        if(tempFinalBlocks){setFinalBlocks(tempFinalBlocks);                        
                            tempYears.push(new CFormatYear(i, tempFinalBlocks));
                        }
                    }
                }            
            }
            
            return tempYears;
        }
       
       
        
    }, [])    
    /**
     * CompoDidUpdate
     */
    useEffect(()=>{
    /*Examine début et fin de tableau d'années - si l'année courante
    a une différence de + 1 avec le début ou la fin, réninit pour
    update les années du calendrier
    */ 
    if (activMonth){
        const refFirst = activMonth.year - 1;
        const refLast = activMonth.year + 1;
        
        if(years && activMonth){
            const firstLoaded = getFirstYear(years)?.year;
            const lastLoaded = getLastYear(years)?.year;
            if (firstLoaded && lastLoaded){
                if(firstLoaded > refFirst || lastLoaded < refLast){
                    const updatedYears = initYearsUpdate(activMonth.year, 2);
                    setYears(updatedYears);
                }
            }
        }
    }
    const checkClick=(e: React.MouseEvent<HTMLDivElement>)=>{
        if (document.getElementById('clickbox')?.contains(e.currentTarget)){
            // Clicked in box
          } else{
            setOpenCalendar({
                ...openCalendar,
                'startdate': false});
          }
    }
    function initYearsUpdate (activYear: number, nbYears: number){
        let tempYears: CFormatYear[] = [];        
        for (let i=activYear-nbYears; i<= activYear + nbYears; i++){
            const loadedYear = loadFullYear(i);
            
            if (loadedYear){   
                const monthsBlocks = formatMonthsBlocks(loadedYear);       
                if (monthsBlocks){
                    const tempFinalBlocks = formatFinalBlocks(monthsBlocks, nbCalendarBlocks, i);
                    if(tempFinalBlocks){setFinalBlocks(tempFinalBlocks);                        
                        tempYears.push(new CFormatYear(i, tempFinalBlocks));
                    }
                }
            }            
        }
        
        return tempYears;
    }
    if (props.name === "rangeDatePicker"){
       
    /*calendar click*/
        if (clickedTimes > 0){          
            // first time
            if (clickedTimes === 1 && letsUpdate){      
                console.log("endDate : ");       
                setLetsUpdate(false);
            }
             // second time
            if (clickedTimes === 2 && letsUpdate){                
                console.log("cliqué 2 fois => endDate or twice");                
                    timerRef.current = setTimeout(() => {
                        setOpenCalendar({...openCalendar, startdate: false});
                        setLetsUpdate(false);
                    }, 500);
                    setLetsUpdate(false);
                
            }
            if (clickedTimes === 3 && letsUpdate){               
                    console.log("cliqué 3 fois");
                    //déselection
                    setEndDate(null);
                    setStartDate(null);
                    props.onStartDateChange(null);
                    props.onEndDateChange(null);
                    setIsInRange([]); // table overed dates
                    setClickedTimes(0);
                    setLetsUpdate(false);                
            }
        }
    }
    return ()=>{
        if(letsUpdate === false && timerRef.current){
            clearTimeout(timerRef.current);
        }
    };
    }, [activMonth, letsUpdate, years, isPreviousGroup, startDate, clickedTimes])    

    function getLastDayOfMonth(date: Date){
        let lastDate: Date;
        lastDate = new Date(date.getFullYear(),date.getMonth(), 0);
        const lastDateNb = lastDate.getDate();
        return lastDateNb;
    }
    function isActivGroup(aGroup: CformatBlock){        
        const monthRef = aGroup.block.find((aDate: CformatDate)=>(aDate.date.getDate() === 17));
        if (monthRef){
        const intMonth = new Date(monthRef.date.getFullYear(), monthRef.date.getMonth(), 17).getTime();
        const intActivMonth = new Date(activMonth.year, activMonth.month, 17).getTime();
        const isActivGroup = intMonth === intActivMonth;  
        return isActivGroup
        }
    }
    function isFurtherGroup(aGroup: CformatBlock){
        let isFurther: boolean;   
        const monthRef = aGroup.block.find((aDate: CformatDate)=>(aDate.date.getDate() === 20));
        if (monthRef){
            const refYear = monthRef.date.getFullYear();
            //further month in the year
            if (activMonth.month < 11){
                isFurther = refYear === activMonth.year && aGroup.id-1 === activMonth.month+1;
            }else{
                // décembre de l'année d'après
                isFurther = refYear === activMonth.year+1 && aGroup.id-1 === 0;
            }   
            return isFurther;  
        }
    }
    function isPreviousGroup(aGroup: CformatBlock){ 
        let isPrev: boolean;   
        const monthRef = aGroup.block.find((aDate: CformatDate)=>(aDate.date.getDate() === 20));
        if (monthRef){
           const refYear = monthRef.date.getFullYear();
        //Previous month in the year
        if (activMonth.month > 0){
            isPrev = refYear === activMonth.year && aGroup.id-1 === activMonth.month-1;
        }else{
            // décembre de l'année d'avant
            isPrev = refYear === activMonth.year-1 && aGroup.id-1 === 11;
        }   
       return isPrev;       
    }
}

    function truncateFromStart (aString: string, index: number){
        return aString.slice(0, index);
    }


    function getAllDaysInMonth(year: number, month: number) {
        const date = new Date(year, month, 1);
        const dates = []; 
        while (date.getMonth() === month) {
          dates.push(new Date(date));
          date.setDate(date.getDate() + 1);
        }
        return dates;
    }
    

    /**
     * Get all raw dates of the year in param
     * @param year 
     * @returns 
     */
    function loadFullYear(year: number): Date[]{        
        const refYearNb = new Date(year, 1, 0).getFullYear();
        let tempCalendarTable = []; // real dates

        // parcours les mois de l'année
        for (let i = 0; i < 12; i++){         
           const fullDates = getAllDaysInMonth(refYearNb, i);
            tempCalendarTable.push(...fullDates);
        }
        return tempCalendarTable;
    }
    function convertToCalendarDate(realMonths: CarrangeBlock[]): CformatBlock[]{
        const newDates = realMonths && realMonths.map((aMonth: CarrangeBlock)=>{
          const newDates =  aMonth && aMonth.block.map((aDate: Date)=>{
                return new CformatDate(aDate, false);
            })
            return new CformatBlock(aMonth.id, newDates);
        })
        return newDates;
    }
    function lastMondayIndexOf (array:CformatDate[]) {
        let index = -1;        
        array.forEach(function(element, i) {
          if (element.date.getDay()===1) index = i;
        });
        return index;
      }
    function calculateMissingBlocksNb(aMonth: CformatBlock, calendarSize: number) {    
            const nbMissedBlocks = calendarSize - aMonth.block.length;
            return nbMissedBlocks;
    }
     /**
     * Take the further dates from next month to complement
     * calendar size
     * @param realMonths 
     * @param blockSize 
     */
     function complementCalendarBlock(realMonths: CformatBlock[], calendarSize: number, year: number){
        /*On parcourt les blocks de l'année*/
        for (let i=0; i< realMonths.length; i ++){
            let aMonth: CformatBlock = realMonths[i];    
            // s'il manque des cases, on complète avec les premières actives du calendrier suivant
                // 1- on cherche combien il manque
                const missingBlocksNb = calculateMissingBlocksNb(aMonth, calendarSize);        
            //On cherche la première date active du mois suivant
            let furtherMonth: CformatDate[] = [];
            if (realMonths[i+1]){
                furtherMonth = realMonths[i+1].block;     
                       
            }else{ // previous month last year = december year-1
                const furtherMonths = loadFullYear(year+1);
                const arrangedFurtherMonths = formatMonthsBlocks(furtherMonths);
                const calendarFurtherMonth = convertToCalendarDate(arrangedFurtherMonths);
                const january = calendarFurtherMonth.find((aMonth: CformatBlock)=>aMonth.id === 1)?.block;        
                if (january){
                    furtherMonth = january;
                }
             }
                // 3- parcourt le mois suivant de l'indice trouvé jusqu'à (l'indice + le nb manquant) pour retirer la liste de compléments
                // 2- On cherche la première date active du calendrier suivant   
                const firstActivBlock = furtherMonth.findIndex((aDate: CformatDate)=>(aDate.isDisabled === false));       
                for (let x=firstActivBlock; x<firstActivBlock+missingBlocksNb; x++){
                    const complement = new CformatDate( furtherMonth[x].date, true);
                    aMonth.block = [...aMonth.block, complement];
                }
            }        
        return realMonths
    }
    /**
     * if the first day is not monday, take the complement from
     * previous month if exists + call complementCalendarBlock to complete
     * empty blocks of calendar
     * @param data 
     * @param realMonths 
     * @param nbRef 
     */
    function formatFinalBlocks(realMonths: CarrangeBlock[], nbRef: number, year: number){ 
        if (realMonths) {
            const tempMonths = convertToCalendarDate(realMonths);
            for (let i=0; i< tempMonths.length; i ++){                
                const theMonth = tempMonths[i];
                let aMonth: CformatDate[] = theMonth.block;      
                const firstDay = aMonth[0];                
                // if first day of calendar is not monday
                if (firstDay.date.getDay() !== 1){
                 //Take the first monday and all the days to end in the previous calendar
                 let previousMonth: CformatDate[] = [];
                 if (tempMonths[i-1]){
                    previousMonth = tempMonths[i-1].block;
                 }else{ // previous month last year = december year-1
                    const previousMonths = loadFullYear(year-1);
                    const arrangedPreviousMonths = formatMonthsBlocks(previousMonths);
                    const calendarPreviousMonth = convertToCalendarDate(arrangedPreviousMonths);
                    const december = calendarPreviousMonth.find((aMonth: CformatBlock)=>aMonth.id === 12)?.block;        
                    if (december){
                        previousMonth = december;
                    }
                 }
                    let lastMondayPreviousMonth : number;
                    if (previousMonth){                 
                        lastMondayPreviousMonth = lastMondayIndexOf(previousMonth);                 
                        for (let x= previousMonth.length-1 ; x >= lastMondayPreviousMonth; x--){
                                const theComplement = new CformatDate(previousMonth[x].date, true); 
                                aMonth.unshift(theComplement);
                        }
                    }
               
                } 
            }
            const finalMonths = complementCalendarBlock(tempMonths, nbRef, year);
            return finalMonths;
        } 
    }
   
   /**
    * Format calendar month per month
    * @param loadedBlocks 
    * @returns 
    */    
    function formatMonthsBlocks(loadedBlocks: Date[]){
        // gestion des blocks de mois
        let filteredBlocks: CarrangeBlock[] = [];
        let monthsResults: any[] = [];
        if(loadedBlocks){            
            let startRef = 0;
            let endRef = startRef;
            let latestChange = 0;
            for (let i = 1; i < loadedBlocks.length; i++){                
                if (loadedBlocks[i].getMonth() !== loadedBlocks[i-1].getMonth()){       
                    endRef = i;
                    let newBlocks = [];
                    // on crée un nouvel objet au changement de mois 
                    for (let j = startRef; j < endRef; j++){
                        const newBlock = loadedBlocks[j];
                        newBlocks.push(newBlock);
                        latestChange = endRef;
                    }
                    
                    startRef = endRef;
                                    
                    // Crée un nouvel objet avec l'indice du mois courant et la liste de jours du mois
                    const newObj = new CarrangeBlock (loadedBlocks[i].getMonth(), newBlocks);
                    filteredBlocks = [...filteredBlocks, newObj];
                    
                }               
            }
             // Dernier mois : récupère l'index du dernier changement et boucle jusqu'à la fin             
             let newFinalBlocks = [];
             for (let i = latestChange; i < loadedBlocks.length; i++){                
                // on crée un nouvel objet pour le dernier mois                
                const newBlock = loadedBlocks[i];
                newFinalBlocks.push(newBlock);                           
            }
                const newObj = new CarrangeBlock(newFinalBlocks[0].getMonth()+1, newFinalBlocks);
                filteredBlocks = [...filteredBlocks, newObj];  
        }
        
        return filteredBlocks;
    }
  
    function indexOf(rangeArray: CinRange[], searchIndex: number){
        let indexes: number[] = [];

        for (let x=0; x< rangeArray.length; x++){           
            if(rangeArray[x].id === searchIndex){
                indexes.push(x);
            };            
        }
        return indexes[0];
    }
   
    const formatUnits = (toFormat: string)=>{
        if(parseInt(toFormat, 10) >=10){
            return toFormat;
        }else{
            return "0"+ toFormat;
        }
    }
    function indexOfBlock(years: CFormatYear[], searchDate: Date){
        let indexes: number[] = [];
        years.forEach((year: CFormatYear) => {
            year.months.forEach((aMonth: CformatBlock)=>{
                aMonth.block.forEach((aBlock: CformatDate, index)=>{
                  if(aBlock.date === searchDate){
                    indexes.push(index)
                  }
                })
            })
        });
        return indexes[0];
    }
    const goBackMonth = (e: React.MouseEvent<HTMLButtonElement>, yearIndex: number, monthIndex: number)=>{
        e.preventDefault();
        // gestion du changement d'année
        if (activMonth.month > 0){
            setActivMonth({year: activMonth.year, month: activMonth.month-1});
        }else{
            // décembre de l'année d'avant
            setActivMonth({year: activMonth.year-1, month: 11});
        }     
       
    }
    const goNextMonth = (e: React.MouseEvent<HTMLButtonElement>,yearIndex: number, monthIndex: number)=>{
        e.preventDefault();
        if (activMonth.month < 11){            
            setActivMonth({year: activMonth.year, month: activMonth.month+1});
        }else{
            // janvier de l'année d'après
            setActivMonth({year: activMonth.year+1, month: 0});
        }
       
    }
   const handleDayOver = (e: React.MouseEvent<HTMLButtonElement>, overDate: CformatDate, index: number)=>{
    const overedId = parseInt(e.currentTarget.id, 10);
    if (endDate === null || endDate === startDate || (clickedTimes > 0 && clickedTimes < 2)){       
        // on cherche dans isInRange, si pas encore dans tableau, on init sinon on supprime
        const found = isInRange.find((aDate: CinRange)=>(aDate.id === overedId)); 
        if (found !== undefined){
            const foundIndex = indexOf(isInRange, overedId);
          
            let updatedIsInRange: CinRange[] = [];
            for (let x = foundIndex; x < isInRange.length; x++){                       
                updatedIsInRange = isInRange.slice(0, foundIndex);
                setIsInRange(updatedIsInRange);
                setOveredStartDateIndex(foundIndex); // reinit à clicked date  
            }              
        }else{                       
            if (clickedDateIndex !== -1 && overDate.isDisabled === false){    
                
                const refDate = overDate.date;
                const refStartDate = startDate && startDate.date;
                if (refDate && refStartDate){                    
                     const overedIndex = indexOfBlock(years, refDate);                     

                     let inRangeElts: CinRange[] = [];
                    // parcourt le calendrier et récupère l'ensemble des
                    // dates entre la date over et la start date pour peupler
                    // isInRange
                     years.forEach((year: CFormatYear) => {
                        year.months.forEach((aMonth: CformatBlock)=>{                         
                                aMonth.block.forEach((aBlock: CformatDate, index)=>{
                                    const lastday = getLastDayOfMonth(aBlock.date);
                                    
                                    if (overDate.isDisabled === false && aBlock.date.getDate() && aBlock.date >= refStartDate && aBlock.date<= refDate){
                                        const inRangeId = parseInt((year.year.toString() + formatUnits(aBlock.date.getMonth().toString()) + formatUnits(index.toString())), 10);
                                        // si date pas déjà dans tab, on la push, sinon on update l'id
                                        const foundDate = inRangeElts.find((inRange: CinRange)=>aBlock.date === inRange.date);
                                        if (foundDate){
                                            const indexToUpdate = inRangeElts.findIndex((inRange: CinRange)=>aBlock.date === inRange.date);
                                            inRangeElts[indexToUpdate] = new CinRange(inRangeId, aBlock.date);
                                        }else{
                                            inRangeElts.push(new CinRange(inRangeId, aBlock.date));  
                                        }
                                    }
                                })                            
                            
                        })
                    })                                            
                    setIsInRange(inRangeElts);
                    setOveredStartDateIndex(overedIndex); // reinit à clicked date
            }
                /**/                
            }
        }
    }
    }
   const isInRangeArray = (theDate: CformatDate)=>{
    
    const tempInRange = isInRange && isInRange.filter((aDate: CinRange)=>(
      aDate.date === theDate.date && !theDate.isDisabled      
      ))
    return (tempInRange[0] !== undefined && tempInRange[0] !== null) ? true : false;
   }

    const handleDayClick =(e: React.MouseEvent<HTMLButtonElement>, date: CformatDate)=>{
        const name = e.currentTarget.name;
        const id = e.currentTarget.id;
        /** convert to string for bd purpose 2023-03-12*/
        const selectedYear = formatUnits(date.date.getFullYear().toString()); //– récupère l'année en 4 chiffres(aaaa)
        const selectedMonth = formatUnits(date.date.getMonth().toString()); //– récupère le mois sous forme numérique (0-11)
        const selectedDate = formatUnits(date.date.getDate().toString()); //– récupère la date sous forme numérique (1-31)
        
        const sDate = selectedYear+"-"+selectedMonth+"-"+selectedDate;

        console.log("clickedDate : ", date);

        if (name === "calendar" || name === "datePicker"){           
            if(date !== startDate){
                props.onStartDateChange(date.date);
                setStartDate(date);
            }
        }
        
        if (name === "rangeDatePicker"){
            setClickedTimes(clickedTimes+1);            
            if (clickedTimes === 0){
                if (date !== startDate){
                    setStartDate(date);
                    props.onStartDateChange(date.date);
                    setClickedDateIndex(parseInt(id, 10));
                    setOveredStartDateIndex(parseInt(id, 10));
                    setIsInRange([]); // reset tab overs
                    setLetsUpdate(true);
                }           
            }else if (clickedTimes === 1){
               /*  if (date !== endDate){ Normalement condition pour twice and enddate ---> à désactiver*/
                    setEndDate(date);
                    props.onEndDateChange(date.date);
                    setLetsUpdate(true);
                    if (date === startDate){setIsInRange([]);}
                /* } */
            }else if(clickedTimes === 2){
                setIsInRange([]); // reset tab overs
                setLetsUpdate(true);
            }else{
                setLetsUpdate(false);
            }
           
        }
    }
    const letsOpenCalendar = (e: React.MouseEvent<HTMLInputElement>)=>{
        const {name, id} = e.currentTarget;
        console.log("open calendar : name ", name);
        console.log("open calendar : id ", id);

        setOpenCalendar({
            ...openCalendar,
            [name]: id==="true"? false : true});
       /*  setClickedTimes(id === "true" ? 3 : 0); */
    }
 const dateIsActiv = (date: CformatDate)=>{    
    return !date.isDisabled && !props.modifiers.disabled(date.date)
};
 const dateIsTwiceClick = (date: CformatDate)=>{return clickedTimes === 2 && startDate && startDate.date && startDate.date === date.date && endDate && endDate.date === date.date};
 const dateIsFirstClick = (date: CformatDate)=>{return startDate && startDate.date === date.date}
 const dateIsEndClick = (date: CformatDate)=>{return clickedTimes === 2 && endDate && endDate.date === date.date && date.date !== startDate?.date}
 const dateIsOvered = (date: CformatDate)=>{return endDate !== startDate && isInRangeArray(date)}
 
 return (
        <div className='datepicker-container'>            
            <div className='input-datepicker-container'>
            <div className='input-datepicker-wrapper'>
                <label htmlFor={openCalendar.startdate.toString()}>
                    {props.name === "rangeDatePicker" ? "Date début" : "Date"}
                </label>
                <div className='input-deco-wrapper'>
                    <input 
                    className='input-date' 
                    type="text"
                    name="startdate"
                    id={openCalendar.startdate.toString()}/**Store value of boolean opencalendar */
                    onClick={(e: React.MouseEvent<HTMLInputElement>)=> letsOpenCalendar(e)}       
                    value={props.startDate ? props.startDate.toLocaleDateString(localeFormat, options) : ""}
                    readOnly={true}
                    >                    
                    </input>
                    <BsCalendar4 className='calendar-deco'/>
                </div>
            </div>
           {props.name === "rangeDatePicker" && <div className='input-datepicker-wrapper'>
                <label htmlFor={openCalendar.enddate.toString()}>
                    {props.name === "rangeDatePicker" ? "Date fin" : ""}
                </label>
                <input 
                className='input-date' 
                type="text"
                name="enddate"
                readOnly={true}
                id={openCalendar.enddate.toString()} /**Store value of boolean opencalendar */
                onClick={(e: React.MouseEvent<HTMLInputElement>)=> letsOpenCalendar(e)}                    
                value={props.endDate ? props.endDate.toLocaleDateString(localeFormat, options) : ""}
                >

                </input>
            </div>}
            </div>
        { openCalendar["startdate"] && <div className='calendar-container'>
        {/*  activ */} 
        <div className='calendar-wrapper'>       
        {/*  les dates */}
        {years && years.map((aYear: CFormatYear, yearIndex: number)=>(
            aYear.months && aYear.months.map((aGroup: CformatBlock, monthIndex: number)=>(
                isActivGroup(aGroup) && <div key={aGroup.id} className='month-container'>                 
                <div className= 'month-group'>
                <div className='year-month-buttons-container'>
                <button 
                onClick={(e: React.MouseEvent<HTMLButtonElement>)=>goBackMonth(e, yearIndex, monthIndex)} 
                className='goback'>{'<'}
                </button>
                <div className='year-month-container'>
                <div className='year-wrapper'>{isActivGroup(aGroup) && aGroup.block[17].date.getFullYear()}</div>    
                <div className={isActivGroup(aGroup) ?'year-month-wrapper':'sliding-year-month-wrapper'}>
                {isActivGroup(aGroup) && months[monthIndex] && months[monthIndex].label}
                </div>
                </div>
                <button 
                onClick={(e: React.MouseEvent<HTMLButtonElement>)=>goNextMonth(e, yearIndex, monthIndex)} 
                className='gonext'>{'>'}
                </button>
                </div>
                <div className='month-th-group'>
                {truncatedDays && truncatedDays.map((aDay: Iday)=>(
                    <div key={aDay.id} className='month-th-element'>
                    {aDay && aDay.label}
                    </div>
                    ))}
                    </div>
                    <div className='box-button-container'>                
                    <div key={aGroup.id} id={aGroup.id.toString()} className='box-button-wrapper'>                                
                    {aGroup && aGroup.block.map((aDate: CformatDate, index: number)=>(
                        <button key={index} 
                        id={aYear.year.toString() + formatUnits(aGroup.id.toString()) + formatUnits(index.toString())} /* ICI for overedDates */
                        name={props.name}
                        className={ dateIsActiv(aDate) && dateIsTwiceClick(aDate) ? 'box-button-twice' : dateIsActiv(aDate) && dateIsFirstClick(aDate) ? 'box-button-clicked': dateIsActiv(aDate) && dateIsEndClick(aDate) ? 'box-button-clicked-end': dateIsActiv(aDate) && dateIsOvered(aDate) ? 'box-button-over' : 'box-button'}
                        disabled={!dateIsActiv(aDate)}
                        onMouseOver={(e: React.MouseEvent<HTMLButtonElement>)=>{props.name === "rangeDatePicker" && handleDayOver(e, aDate, index)}} 
                        onClick={(e:React.MouseEvent<HTMLButtonElement>)=>
                            handleDayClick(e, aDate)                            
                        }
                            
                            >{aDate && aDate.date.getDate()}</button>
                        ))}
                        </div>
                        </div>
                        </div>                
                        </div>
                        
                        ))))}
        </div> 
                        
        {/*  further */} 
        <div className='calendar-wrapper'>       
        {/*  les dates */}
        {years && years.map((aYear: CFormatYear, yearIndex: number)=>(
            aYear.months && aYear.months.map((aGroup: CformatBlock, monthIndex: number)=>(
                isFurtherGroup(aGroup) && <div key={aGroup.id} className='month-container'>                 
                <div className= 'month-group'>
                <div className='year-month-buttons-container'>
                <button 
                onClick={(e: React.MouseEvent<HTMLButtonElement>)=>goBackMonth(e, yearIndex, monthIndex)} 
                className='goback'>{'<'}
                </button>
                <div className='year-month-container'>
                <div className='year-wrapper'>{isFurtherGroup(aGroup) && aGroup.block[17].date.getFullYear()}</div>    
                <div className={isFurtherGroup(aGroup) ?'year-month-wrapper':'sliding-year-month-wrapper'}>
                {isFurtherGroup(aGroup) && months[monthIndex] && months[monthIndex].label}
                </div>
                </div>
                <button 
                onClick={(e: React.MouseEvent<HTMLButtonElement>)=>goNextMonth(e, yearIndex, monthIndex)} 
                className='gonext'>{'>'}
                </button>
                </div>
                <div className='month-th-group'>
                {truncatedDays && truncatedDays.map((aDay: Iday)=>(
                    <div key={aDay.id} className='month-th-element'>
                    {aDay && aDay.label}
                    </div>
                    ))}
                    </div>
                    <div className='box-button-container'>                
                    <div key={aGroup.id} id={aGroup.id.toString()} className='box-button-wrapper'>                                
                    {aGroup && aGroup.block.map((aDate: CformatDate, index: number)=>(
                        <button key={index} 
                        id={aYear.year.toString() + formatUnits(aGroup.id.toString()) + formatUnits(index.toString())} /* ICI for overedDates */
                        name={props.name}
                        className={ dateIsActiv(aDate) && dateIsTwiceClick(aDate) ? 'box-button-twice' : dateIsActiv(aDate) && dateIsFirstClick(aDate) ? 'box-button-clicked': dateIsActiv(aDate) && dateIsEndClick(aDate) ? 'box-button-clicked-end': dateIsActiv(aDate) && dateIsOvered(aDate) ? 'box-button-over' : 'box-button'}
                        disabled={!dateIsActiv(aDate)}
                        onMouseOver={(e: React.MouseEvent<HTMLButtonElement>)=>{props.name === "rangeDatePicker" && handleDayOver(e, aDate, index)}} 
                        onClick={(e:React.MouseEvent<HTMLButtonElement>)=>
                            handleDayClick(e, aDate)                            
                        }
                            
                            >{aDate && aDate.date.getDate()}</button>
                        ))}
                        </div>
                        </div>
                        </div>                
                        </div>
                        
                        ))))}
        </div> 
         </div>}
         </div>
        )}
            
            export default DatePicker


